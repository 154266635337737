import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import { AccountIcon, SaveIcon, CrayonIcon, DossierIcon, ShoppingcartIcon } from '../Svg/Icons';
import styles from '../styles/app.module.css';
import {
  Link
} from "react-router-dom";
import i18n from "i18next";
import {initReactI18next, useTranslation} from 'react-i18next';
import { useHistory } from "react-router-dom";

const drawerWidth = 300;
const drawerWidthMobile = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Titillium Web'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer,
  },
  drawerPaper: {
    width: drawerWidth,
    flexShrink: 0,
    background: theme.palette.navbgcolor.main,
  },
  drawerPaperMobile: {
    width: drawerWidthMobile,
    flexShrink: 0,
    background: theme.palette.navbgcolor.main,
  },
  drawerPaperContainer: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  buttonContainer: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderRadius: '13px',
    width: '220px',
    height: '40px',
    background: theme.palette.navbuttoncolor.main,
    color: theme.palette.navbuttoncolor.contrastText,
    '&:hover': {
       background: theme.palette.navbuttoncolor.main,
    },
    justifyContent: 'flex-start',
  },
  button2: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    borderRadius: '13px',
    width: '220px',
    height: '40px',
    background: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    '&:hover': {
       background: theme.palette.info.main,
    },
    justifyContent: 'center',
  },
  buttonOutline: {
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    width: '220px',
    height: '40px',
    color: theme.palette.buttonbgcolor.main,
    justifyContent: 'flex-start',
  },
  buttonSvg: {
    fontSize: '34px',
    fill: theme.palette.navbuttoncolor.main,
    stroke: theme.palette.navbuttoncolor.main,
  },
  buttonSvgInverted: {
    fontSize: '34px',
    fill: theme.palette.navtextcolor.main,
    stroke: theme.palette.navtextcolor.main,
  },
  gridContainer: {
    width: '200px',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.navtextcolor.main,
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightLight,
  },
  logoContainer: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  logo: {
    maxWidth: '200px',
    height: 'auto',
    padding: theme.spacing(2),
    marginLeft: 'auto'
  },
  linkicon: {
    padding: theme.spacing(1),
  },
  select: {
    color: theme.palette.navtextcolor.main,
    textAlign: 'left',
    "& .MuiSelect-select:focus": {
      color: theme.palette.navtextcolor.main,
    },
  },
  choice: {
    textAlign: 'left'
  },
  divider: {
    margin: theme.spacing(3),
    background: theme.palette.navtextcolor.main,
    opacity: '.5',
    height: '2px'
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  formControl: {
    minWidth: '100%',
    marginBottom: theme.spacing(2),
    color: theme.palette.navtextcolor.main,
    opacity: '.7',
    "& :focus": {
      opacity: '1',
    },
    "& .Mui-focused": {
      color: theme.palette.navtextcolor.main,
      borderColor: theme.palette.navtextcolor.main,
      opacity: '1',
    },
    "& .Mui-focused:after": {
      borderColor: theme.palette.navtextcolor.main,
    }
  },
  label: {
    color: theme.palette.navtextcolor.main,
  },
  underline: {
    borderColor: theme.palette.navtextcolor.main,
  },
  appBar: {
    background: theme.palette.navbgcolor.main,
    color: theme.palette.navtextcolor.main,
    zIndex: theme.zIndex.drawer + 1,
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
}));

export default function NavBar(props) {
  const { window, categories, language, switchLang, translations } = props;
  const classes = useStyles(props);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  let history = useHistory();

  const [locale, setLocale] = React.useState(language);
  const handleChange = (event) => {
    switchLang(event.target.value)
    setLocale(event.target.value)
    history.push('/' + event.target.value)
    handleDrawerClose()
  };

  const drawer = (
    <div className={classes.drawerPaperContainer}>
      <div className={classes.logoContainer}>
        <Link onClick={handleDrawerClose} className={classes.link} to={'/' + locale}>
          <picture>
            <source srcSet={props.base.logo.xs.webp} type="image/webp" />
            <source srcSet={props.base.logo.xs.url} type="image/jpeg" />
            <img width={props.base.logo.size.width} height={props.base.logo.size.height} className={classes.logo} src={props.base.logo.xs.url} alt={props.base.settings['store_name']} />
          </picture>
        </Link>
      </div>
      <Box display="flex" flexDirection="column" width={1} alignItems="center" justifyContent="center">
        <Link onClick={handleDrawerClose} className={classes.link} to={'/' + locale + '/' + translations["link_account_url"]}>
          <Button variant="contained" className={classes.button} startIcon={<AccountIcon className={classes.buttonSvg} />}>
          {translations["link_account"]}
          </Button>
        </Link>
        <Link onClick={handleDrawerClose} className={classes.link} to={'/' + locale + '/' + translations["link_projects_url"]}>
          <Button variant="contained" className={classes.button} startIcon={<SaveIcon className={classes.buttonSvg} />}>
          {translations["link_saves"]}
          </Button>
        </Link>
        <Link onClick={handleDrawerClose} className={classes.link} to={'/' + locale + '/' + translations["link_orders_url"]}>
          <Button variant="contained" className={classes.button} startIcon={<DossierIcon className={classes.buttonSvg} />}>
          {translations["orders"]}
          </Button>
        </Link>
      </Box>
      <Divider className={classes.divider} />
      <Box display="flex" flexDirection="column" width={1} alignItems="center" justifyContent="center">
        <Link onClick={handleDrawerClose} className={classes.link} to={'/' + locale + '/' + translations["link_projects_url"] + '/' + translations["link_projects_new_url"]}>
          <Button className={classes.buttonOutline} startIcon={<CrayonIcon className={classes.buttonSvgInverted} />} disableElevation>
            {translations["link_new_project"]}
          </Button>
        </Link>
        <Link onClick={handleDrawerClose} className={classes.link} to={'/' + locale + '/' + translations["link_shoppingcart_url"]}>
          <Button className={classes.buttonOutline} startIcon={<ShoppingcartIcon className={classes.buttonSvgInverted} />} disableElevation>
          {translations["link_shoppingcart"]}
          </Button>
        </Link>
        <Link onClick={handleDrawerClose} className={classes.link} to={'/back-to-site'}>
          <Button variant="contained" className={classes.button2}>
          {translations["back_to_website"]}
          </Button>
        </Link>
      </Box>
    </div>
  );

  const localeSwitch = (version) => (
    <div className={classes.logoContainer}>
    <Grid
      container
      className={classes.gridContainer}
      direction="row"
      justify="center"
      alignItems="flex-end"
      color="primary"
    >
      <FormControl className={classes.formControl} classes={{root: classes.formControl}}>
        <InputLabel underline={classes.label} className={classes.label} id="language-select-label">{translations["link_language"]}</InputLabel>
        <Select
          labelId="language-label"
          id={version}
          value={locale}
          onChange={handleChange}
          className={classes.select}
        >
          {props.base.languages.map(({ code, title }, index) => (
          <MenuItem className={classes.choice} value={code}>{title}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.link}
              >
                <MenuIcon />
              </IconButton>
              <Typography className={classes.link} variant="h6" noWrap>
                {props.base.settings['store_name']}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            container={container}
            variant="temporary"
            anchor='left'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaperMobile,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
            {localeSwitch('language-select-mobile')}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
            {localeSwitch('language-select-desktop')}
          </Drawer>
        </Hidden>
      </nav>
  );
}
