import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MetaTags from 'react-meta-tags';
import { Translation } from 'react-i18next';
import styles from '../styles/app.module.css';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import charnierepercee from '../Svg/charnierepercee.svg';
import charniere from '../Svg/charniere.svg';
import HeaderCart from '../Components/HeaderCart';
import Stepper from '../Components/Stepper';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import { HeadIcon, ShoppingcartIcon, CircleIcon, CrayonIcon, SaveIcon, TrashIcon } from '../Svg/Icons';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getProducts, getProductDetail, saveProductDetail, getProjectsSaved, deleteProductDetail, addToCart } from '../Api/RestApi'
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  useHistory,
  useParams,
  useLocation
} from "react-router-dom";
import filterObjectArray from 'filter-object-array';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '100vw'
  },
  textField: {
    width: '25ch',
  },
  account_content: {
    margin: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    backgroundColor: "#fff",
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       padding: theme.spacing(2),
    },
  },
  header_content: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
    },
  },
  total_content: {
    margin: theme.spacing(2),
    marginRight: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
    },
  },
  header_class: {
    fontSize: '24px',
    margin: '0',
    padding: '0',
    lineHeight: '30px',
    marginBottom: '3px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '2px',
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       fontSize: '16px',
       lineHeight: '22px',
    },
  },
  price_class: {
    textAlign: 'right',
    fontSize: '30px',
    marginTop: 0,
    marginBottom: 0
  },
  header2_class: {
    fontSize: '20px',
    margin: '0',
    padding: '0',
    lineHeight: '30px',
    marginBottom: '3px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '2px',
    margin: theme.spacing(3),
    marginLeft: 0,
    marginBottom: 0,
    color: theme.palette.error.main,
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       fontSize: '16px',
       lineHeight: '22px',
    },
  },
  title_class: {
    fontSize: '14px',
    marginTop: '5px',
    padding: '0',
    lineHeight: '20px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: '2px',
  },
  free_class: {
    fontSize: '14px',
    marginTop: '0',
    padding: '0',
    lineHeight: '14px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '2px',
  },
  cart_nb: {
    fontSize: '20px',
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginBottom: 0,
    textAlign: 'right',
    padding: '0',
    lineHeight: '20px',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  cart_total: {
    fontSize: '16px',
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    textAlign: 'right',
    padding: '0',
    lineHeight: '20px',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  mt10: {
    marginTop: '10px',
  },
  mt25: {
    marginTop: '28px',
  },
  total_class: {
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
  subtitle_class: {
    fontSize: '11px',
    marginTop: '28px',
    margin: '0',
    padding: '0',
    lineHeight: '20px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: '25px',
    height: '20px',
    width: '20px',
    textAlign: 'center',
  },
  subtitle_class_a: {
    backgroundColor: theme.palette.primary.main,
  },
  subtitle_class_b: {
    backgroundColor: theme.palette.success.main,
  },
  subtitle_class_c: {
    backgroundColor: theme.palette.warning.main,
  },
  subtitle_class_d: {
    backgroundColor: theme.palette.secondary.main,
  },
  buttonSvg1: {
    fontSize: '35px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg2: {
    fontSize: '35px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  form: {
    borderRadius: '25px',
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.light.main,
    color: theme.palette.light.contrastText,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '50px',
  },
  slider: {
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  range: {
    marginBottom: theme.spacing(1),
  },
  table_content: {
    margin: theme.spacing(4),
    backgroundColor: "#fff",
  },
  selectEpaisseur: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '70px',
  },
  epaisseur: {
    minWidth: '36px',
    width: '36px',
    height: '36px',
    borderRadius: '18px',
    lineHeight: '30px',
    textAlign: 'center',
    border: '3px solid transparent',
    cursor: 'pointer'
  },
  inputEpaisseur: {
    marginRight: theme.spacing(1),
    display: 'none',
    '&:checked + div': {
      border: '3px solid #ffc80a'
    }
  },
  options: {
    marginTop: '30px',
    borderTop: '2px solid #f6f7fe'
  },
  totalprice_class: {
    borderBottom: '2px solid #f6f7fe'
  },
  add_cart: {
    borderRadius: '25px',
    border: '3px solid #deae0a',
    color: '#deae0a',
    textTransform: 'uppercase',
    marginTop: '20px',
    marginBottom: '30px',
    '&:hover': {
      border: '3px solid #deae0a',
    }
  },
  head_class: {
    fontSize: '11px',
    margin: '0',
    padding: '0',
    lineHeight: '20px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.white,
    borderRadius: '25px',
    height: '20px',
    width: '20px',
    textAlign: 'center',
    display: 'inline-block',
  },
  input_quantity: {
    borderRadius: '0',
    border: 'solid 1px #000f47',
    borderRigth: '0',
    textAlign: 'center'
  },
  error_stock: {
    fontSize: '14px',
    marginTop: '5px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: '2px',
    textAlign: 'right',
    marginLeft: theme.spacing(4)
  },
  button1: {
    fontSize: '15px',
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
  },
}));

function valuetext(label) {
  return label;
}

function valueslider(values) {
  var arr = [];
  values.map((value, index) => (
    arr.push({value: value, label: value + 'mm' })
  ))
  return arr;
}

function Project(props) {
  const classes = useStyles();
  const { language, translations, logged, products, details, cart, data } = props
  let history = useHistory();

  const [locale, setLocale] = React.useState(language);

  const [open, setOpen] = React.useState(false);

  const { id } = useParams()

  if (locale == 'fr') {
    var matiereSt = 'acier';
  } else if (locale == 'en') {
    var matiereSt = 'steel';
  } else if (locale == 'de') {
    var matiereSt = 'Stahl';
  }

  const [values, setValues] = React.useState({
    cart_nb: 0,
    cart_total: 0,
    product_id: '',
    products: [],
    products_filter: [],
    matiere: matiereSt,
    matieres: [],
    largeur: 40,
    largeurs: [],
    epaisseur: 1.2,
    epaisseurs: [],
    longueur: '',
    longueurs: [],
    diameter: '0',
    bord: '0',
    antraxe: '0',
    charnon: '0',
    broche: '0',
    quantity: '0',
    quantity_max: '0',
    isLoaded: false,
    isCustomizable: false,
    checkedPercable: false,
    checkedFraisurable: false,
    isDisPercable: false,
    isDisFraisurable: true,
    fraisurableFree: false,
    quantity: '0',
    price: '0',
    pricePiece: '0',
    priceCoupage: '0',
    pricePercage: '0',
    priceFraisurage: '0',
    errorQuantity: false,
    nextFree: false
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value,
      quantity: '0',
      price: '0',
      pricePiece: '0',
      priceCoupage: '0',
      pricePercage: '0',
      priceFraisurage: '0',
      fraisurableFree: false,
      errorQuantity: false,
    });
  };

  const handleSwitchPercable = (event) => {
    if ((values.largeur) >= '120') {
      var diameterSt = 6;
    } else if ((values.largeur) >= '80') {
      var diameterSt = 5;
    } else if ((values.largeur) >= '40') {
      var diameterSt = 4;
    }

    if ((values.largeur) >= '120') {
      var anthraxeSt = 60;
    } else if ((values.largeur) >= '100') {
      var anthraxeSt = 50;
    } else if ((values.largeur) >= '60') {
      var anthraxeSt = 35;
    } else if ((values.largeur) >= '50') {
      var anthraxeSt = 28;
    } else if ((values.largeur) >= '40') {
      var anthraxeSt = 22.7;
    }

    if (event.target.checked) {
      var isDisFraisurableSt = false;
      var checkedPercable = true;
    } else {
      var isDisFraisurableSt = true;
      var checkedFraisurableSt = false;
      var checkedPercable = false;
      var diameterSt = 0;
    }

    if (values.quantity > 0) {
      getProductDetail(values.matiere, values.epaisseur, values.largeur, checkedPercable, false, values.quantity, values.longueur, window.location.hostname).then(data => {
        if ((checkedPercable) && (data['percee'])) {
          var checkedFraisurable = true;
          var fraisurableFree = true;
        } else {
          var checkedFraisurable = values.checkedFraisurable;
          var fraisurableFree = false;
        }
        if (values.quantity > data['quantity']) {
          var quantity_to_add = data['quantity'];
          var errorQuantity = true;
        } else {
          var quantity_to_add = values.quantity;
          var errorQuantity = false;
        }
        setValues({
          ...values,
          checkedPercable: checkedPercable,
          isDisFraisurable: isDisFraisurableSt,
          checkedFraisurable: checkedFraisurableSt,
          fraisurableFree: false,
          diameter: diameterSt,
          anthraxe: anthraxeSt,
          errorQuantity: false,
          product_id: data['product_id'],
          price: data['price_total'],
          pricePiece: data['price_piece'],
          priceCoupage: data['price_coupage'],
          priceFraisurage: data['price_fraisurage'],
          pricePercage: data['price_percage'],
          errorQuantity: errorQuantity,
        });
      })
   } else {
     setValues({
       ...values, [event.target.name]: event.target.checked,
       isDisFraisurable: isDisFraisurableSt,
       checkedFraisurable: checkedFraisurableSt,
       fraisurableFree: false,
       diameter: diameterSt,
       anthraxe: anthraxeSt,
       quantity: '0',
       price: '0',
       pricePiece: '0',
       priceCoupage: '0',
       pricePercage: '0',
       priceFraisurage: '0',
       errorQuantity: false,
    });
   }
  };

  const handleSwitch = (event) => {
    if (values.quantity > 0) {
      if (event.target.checked) {
        var checkedFraisurable = true;
      } else {
        var checkedFraisurable = false;
      }
      getProductDetail(values.matiere, values.epaisseur, values.largeur, values.checkedPercable, checkedFraisurable, values.quantity, values.longueur, window.location.hostname).then(data => {
        if (values.quantity > data['quantity']) {
          var quantity_to_add = data['quantity'];
          var errorQuantity = true;
        } else {
          var quantity_to_add = values.quantity;
          var errorQuantity = false;
        }
        setValues({
          ...values,
          checkedFraisurable: checkedFraisurable,
          errorQuantity: false,
          product_id: data['product_id'],
          price: data['price_total'],
          pricePiece: data['price_piece'],
          priceCoupage: data['price_coupage'],
          priceFraisurage: data['price_fraisurage'],
          pricePercage: data['price_percage'],
          errorQuantity: errorQuantity,
        });
      })
     } else {
      setValues({ ...values, [event.target.name]: event.target.checked,
        quantity: '0',
        price: '0',
        pricePiece: '0',
        priceCoupage: '0',
        pricePercage: '0',
        priceFraisurage: '0',
        fraisurableFree: false,
        errorQuantity: false,
      });
    }
  };

  const addQuantity = (event) => {
    var new_quantity = ++values.quantity;
    getProductDetail(values.matiere, values.epaisseur, values.largeur, values.checkedPercable, values.checkedFraisurable, new_quantity, values.longueur, window.location.hostname).then(data => {
      if ((values.checkedPercable) && (data['percee'])) {
        var checkedFraisurable = true;
        var fraisurableFree = true;
      } else {
        var checkedFraisurable = values.checkedFraisurable;
        var fraisurableFree = false;
      }
      if (new_quantity > data['quantity']) {
        var quantity_to_add = data['quantity'];
        var errorQuantity = true;
      } else {
        var quantity_to_add = new_quantity;
        var errorQuantity = false;
      }
      if ((data['piece_par_ref'] > 1) && (new_quantity <= 2)) {
        var nextFree = true;
      } else {
        var nextFree = false;
      }

      setValues({ ...values,
        nextFree: nextFree,
        quantity: quantity_to_add,
        quantity_max: data['quantity'],
        checkedFraisurable: checkedFraisurable,
        fraisurableFree: fraisurableFree,
        product_id: data['product_id'],
        price: data['price_total'],
        pricePiece: data['price_piece'],
        priceCoupage: data['price_coupage'],
        priceFraisurage: data['price_fraisurage'],
        pricePercage: data['price_percage'],
        errorQuantity: errorQuantity,
      });
    })
  }

  const redQuantity = (event) => {
    if (values.quantity > 1) {
      var new_quantity = --values.quantity;
      getProductDetail(values.matiere, values.epaisseur, values.largeur, values.checkedPercable, values.checkedFraisurable, new_quantity, values.longueur, window.location.hostname).then(data => {
        if ((data['piece_par_ref'] > 1) && (new_quantity <= 2)) {
          var nextFree = true;
        } else {
          var nextFree = false;
        }
        setValues({ ...values, quantity: new_quantity, product_id: data['product_id'], price: data['price_total'], pricePiece: data['price_piece'], priceCoupage: data['price_coupage'], priceFraisurage: data['price_fraisurage'], pricePercage: data['price_percage'], nextFree: nextFree });
      })
    }
  }

  const addCart = (event) => {
    addToCart(details.id, details.email, values.quantity, values.product_id, values.checkedPercable, values.checkedFraisurable, values.longueur, window.location.hostname).then(data => {
      const action1 = { type: "TOGGLE_CART", value: data }
      props.dispatch(action1)
    })
  }

  const saveProject = (event) => {
    saveProductDetail(details.id, id, values.title, values.matiere, values.epaisseur, values.largeur, values.checkedPercable, values.checkedFraisurable, values.quantity, values.longueur, window.location.hostname).then(data => {
      setOpen(true);
    })
  }

  const deleteProject = (event) => {
    deleteProductDetail(details.id, id, window.location.hostname).then(data => {
      history.push('/' + language + '/' + translations["link_projects_url"])
    })
  }

  const handleChangeMatiere = (prop) => (event) => {
    const filtersMatchType = {
      "att5" : event.target.value.toString(),
    };
    filterObjectArray({ array: values.products, objFilter: filtersMatchType }).then(function(result) {
      const largeurs = [...new Set(result.map(item => item['att103']))];
      const filtersMatchType1 = {
        "att5" : event.target.value.toString(),
        "att103" : Math.min.apply(Math, largeurs).toString(),
      };
      filterObjectArray({ array: values.products, objFilter: filtersMatchType1 }).then(function(result1) {
        const epaisseurs = [...new Set(result1.map(item => item['att104']))];
        const filtersMatchType2 = {
          "att5" : event.target.value.toString(),
          "att103" : Math.min.apply(Math, largeurs).toString(),
          "att104" : Math.min.apply(Math, epaisseurs).toString(),
        };
        filterObjectArray({ array: values.products, objFilter: filtersMatchType2 }).then(function(result2) {
          var longueurs = [];
          var longueurs_mutli = result2.map(({ id, att2, att5, att103, att104, att110, att115, att122, att123 }, index) => {
            return [(att2 / (att115*2)), att115]
          })
          var longueurs_props = [];
          longueurs_mutli.forEach(function(n , index){
            for(let i = 1; i <= n[0]; i++){
              var new_prop = i * (n[1] * 2)
              if (!longueurs_props.includes(new_prop)) {
                longueurs_props.push(new_prop);
              }
            }
          });
          if (Math.min.apply(Math, largeurs) < '40') {
            var isDisPercableSt = true;
            var isDisFraisurableSt = true;
          } else {
            var isDisPercableSt = false;
            var isDisFraisurableSt = true;
            var diameterSt = 0;
          }

          var charnons = result2.map(({ att115 }, index) => {
            return att115
          })

          setValues({
            ...values, [prop]: event.target.value,
            isDisPercable: isDisPercableSt,
            isDisFraisurable: isDisFraisurableSt,
            checkedPercable: false,
            fraisurableFree: false,
            checkedFraisurable: false,
            largeur: Math.min.apply(Math, largeurs),
            products_filter: result2,
            largeurs: largeurs.sort(function(a, b){return a-b}),
            epaisseur: Math.min.apply(Math, epaisseurs),
            epaisseurs: epaisseurs.sort(function(a, b){return a-b}),
            longueurs: longueurs_props.sort(function(a, b){return a-b}),
            longueur : Math.max(...longueurs_props),
            charnon : Math.min.apply(Math, charnons),
            diameter: diameterSt,
            quantity: '0',
            price: '0',
            pricePiece: '0',
            priceCoupage: '0',
            pricePercage: '0',
            priceFraisurage: '0',
            errorQuantity: false,
            nextFree: false,
          });
        });
      });
    });
  };

  const handleChangeSlider = (event, newValue) => {
    const filtersMatchType = {
      "att5" : values.matiere.toString(),
      "att103" : newValue.toString(),
    };
    filterObjectArray({ array: values.products, objFilter: filtersMatchType }).then(function(result) {
      const epaisseurs = [...new Set(result.map(item => item['att104']))];
      const filtersMatchType2 = {
        "att5" : values.matiere.toString(),
        "att103" : newValue.toString(),
        "att104" : Math.min.apply(Math, epaisseurs).toString(),
      };
      filterObjectArray({ array: values.products, objFilter: filtersMatchType2 }).then(function(result) {
        var longueurs = [];
        var longueurs_mutli = result.map(({ id, att2, att5, att103, att104, att110, att115, att122, att123 }, index) => {
          return [(att2 / (att115*2)), att115]
        })
        var longueurs_props = [];
        longueurs_mutli.forEach(function(n , index){
          for(let i = 1; i <= n[0]; i++){
            var new_prop = i * (n[1] * 2)
            if (!longueurs_props.includes(new_prop)) {
              longueurs_props.push(new_prop);
            }
          }
        });
        if (newValue < '40') {
          var isDisPercableSt = true;
          var isDisFraisurableSt = true;
          var diameterSt = 0;
        } else {
          var isDisPercableSt = false;
          var isDisFraisurableSt = true;
        }

        var charnons = result.map(({ att115 }, index) => {
          return att115
        })

        var broches = result.map(({ att121 }, index) => {
          return att121
        })

        setValues({ ...values,
          largeur: newValue,
          isDisPercable: isDisPercableSt,
          isDisFraisurable: isDisFraisurableSt,
          checkedPercable: false,
          checkedFraisurable: false,
          fraisurableFree: false,
          products_filter: result,
          epaisseur: Math.min.apply(Math, epaisseurs),
          epaisseurs: epaisseurs.sort(function(a, b){return a-b}),
          longueurs: longueurs_props.sort(function(a, b){return a-b}),
          longueur : Math.max(...longueurs_props),
          charnon : Math.min.apply(Math, charnons),
          broche : Math.min.apply(Math, broches),
          diameter: diameterSt,
          quantity: '0',
          price: '0',
          pricePiece: '0',
          priceCoupage: '0',
          pricePercage: '0',
          priceFraisurage: '0',
          errorQuantity: false,
          nextFree: false,
        });
      });
    });
  };

  const handleChangeEpaisseur = (prop) => (event) => {
    const filtersMatchType = {
      "att5" : values.matiere.toString(),
      "att103" : values.largeur.toString(),
      "att104" : event.target.value,
    };
    filterObjectArray({ array: values.products, objFilter: filtersMatchType }).then(function(result) {
      var longueurs = [];
      var longueurs_mutli = result.map(({ id, att2, att5, att103, att104, att110, att115, att122, att123 }, index) => {
        return [(att2 / (att115*2)), att115]
      })
      var longueurs_props = [];
      longueurs_mutli.forEach(function(n , index){
        for(let i = 1; i <= n[0]; i++){
          var new_prop = i * (n[1] * 2)
          if (!longueurs_props.includes(new_prop)) {
            longueurs_props.push(new_prop);
          }
        }
      });

      var charnons = result.map(({ att115 }, index) => {
        return att115
      })

      var broches = result.map(({ att121 }, index) => {
        return att121
      })

      setValues({
        ...values, [prop]: event.target.value,
        products_filter: result,
        longueurs: longueurs_props.sort(function(a, b){return a-b}),
        longueur : Math.max(...longueurs_props),
        charnon : Math.min.apply(Math, charnons),
        broche : Math.min.apply(Math, broches),
        isCustomizable: true,
        fraisurableFree: false,
        quantity: '0',
        price: '0',
        pricePiece: '0',
        priceCoupage: '0',
        pricePercage: '0',
        priceFraisurage: '0',
        errorQuantity: false,
        nextFree: false,
      });
    });
  };

  const handleProducts = () => {
    getProducts(language, window.location.hostname).then(data => {
      const action = { type: "TOGGLE_LIST", value: data }
      props.dispatch(action)
      const filtersMatchType = {
        "att5" : values.matiere.toString(),
      };
      filterObjectArray({ array: data.products, objFilter: filtersMatchType }).then(function(result) {
        const largeurs = [...new Set(result.map(item => item['att103']))];
        const filtersMatchType = {
          "att5" : values.matiere.toString(),
          "att103" : values.largeur.toString(),
        };
        filterObjectArray({ array: data.products, objFilter: filtersMatchType }).then(function(result) {
          const epaisseurs = [...new Set(result.map(item => item['att104']))];
          const filtersMatchType = {
            "att5" : values.matiere.toString(),
            "att103" : values.largeur.toString(),
            "att104" : values.epaisseur.toString(),
          };
          filterObjectArray({ array: data.products, objFilter: filtersMatchType }).then(function(result) {
            var longueurs = [];
            var longueurs_mutli = result.map(({ id, att2, att5, att103, att104, att110, att115, att122, att123 }, index) => {
              return [(att2 / (att115*2)), att115]
            })
            var longueurs_props = [];
            longueurs_mutli.forEach(function(n , index){
              for(let i = 1; i <= n[0]; i++){
                var new_prop = i * (n[1] * 2)
                if (!longueurs_props.includes(new_prop)) {
                  longueurs_props.push(new_prop);
                }
              }
            });

            var charnons = result.map(({ att115 }, index) => {
              return att115
            })

            var broches = result.map(({ att121 }, index) => {
              return att121
            })

            setValues({
              ...values,
              products: data.products,
              products_filter: result,
              largeurs: largeurs.sort(function(a, b){return a-b}),
              epaisseurs: epaisseurs.sort(function(a, b){return a-b}),
              matieres: data.filters['att5'],
              longueurs: longueurs_props.sort(function(a, b){return a-b}),
              longueur : Math.max(...longueurs_props),
              charnon : Math.min.apply(Math, charnons),
              broche : Math.min.apply(Math, broches),
              isLoaded: true,
              quantity: '0',
              price: '0',
              pricePiece: '0',
              priceCoupage: '0',
              pricePercage: '0',
              priceFraisurage: '0',
              fraisurableFree: false,
              errorQuantity: false,
              nextFree: false,
            });
          });
        });
      });
    })
  }

  const handleProductsSaved = () => {
    getProjectsSaved(id, details.id, details.email, window.location.hostname).then(project => {
      getProducts(language, window.location.hostname).then(data => {
        const action = { type: "TOGGLE_LIST", value: data }
        props.dispatch(action)
        const filtersMatchType = {
          "att5" : project.matiere.toString(),
        };
        filterObjectArray({ array: data.products, objFilter: filtersMatchType }).then(function(result) {
          const largeurs = [...new Set(result.map(item => item['att103']))];
          const filtersMatchType = {
            "att5" : project.matiere.toString(),
            "att103" : project.largeur.toString(),
          };
          filterObjectArray({ array: data.products, objFilter: filtersMatchType }).then(function(result) {
            const epaisseurs = [...new Set(result.map(item => item['att104']))];
            const filtersMatchType = {
              "att5" : project.matiere.toString(),
              "att103" : project.largeur.toString(),
              "att104" : project.epaisseur.toString(),
            };
            filterObjectArray({ array: data.products, objFilter: filtersMatchType }).then(function(result) {
              var longueurs = [];
              var longueurs_mutli = result.map(({ id, att2, att5, att103, att104, att110, att115, att122, att123 }, index) => {
                return [(att2 / (att115*2)), att115]
              })
              var longueurs_props = [];
              longueurs_mutli.forEach(function(n , index){
                for(let i = 1; i <= n[0]; i++){
                  var new_prop = i * (n[1] * 2)
                  if (!longueurs_props.includes(new_prop)) {
                    longueurs_props.push(new_prop);
                  }
                }
              });

              var charnons = result.map(({ att115 }, index) => {
                return att115
              })

              var broches = result.map(({ att121 }, index) => {
                return att121
              })

              if (parseInt(project.largeur) >= '120') {
                var diameterSt = 6;
              } else if (parseInt(project.largeur) >= '80') {
                var diameterSt = 5;
              } else if (parseInt(project.largeur) >= '40') {
                var diameterSt = 4;
              }

              if (parseInt(project.largeur) >= '120') {
                var anthraxeSt = 60;
              } else if (parseInt(project.largeur) >= '100') {
                var anthraxeSt = 50;
              } else if (parseInt(project.largeur) >= '60') {
                var anthraxeSt = 35;
              } else if (parseInt(project.largeur) >= '50') {
                var anthraxeSt = 28;
              } else if (parseInt(project.largeur) >= '40') {
                var anthraxeSt = 22.7;
              }

              if (project.percable) {
                var isDisFraisurableSt = false;
              } else {
                var isDisFraisurableSt = true;
                var checkedFraisurableSt = false;
                var diameterSt = 0;
              }

              setValues({
                ...values,
                title : project.title,
                products: data.products,
                products_filter: result,
                largeurs: largeurs.sort(function(a, b){return a-b}),
                epaisseurs: epaisseurs.sort(function(a, b){return a-b}),
                matieres: data.filters['att5'],
                longueurs: longueurs_props.sort(function(a, b){return a-b}),
                matiere: project.matiere,
                epaisseur: project.epaisseur.toString(),
                longueur : project.longueur.toString(),
                largeur : project.largeur.toString(),
                checkedPercable: project.percable,
                checkedFraisurable: project.fraisurable,
                charnon : Math.min.apply(Math, charnons),
                broche : Math.min.apply(Math, broches),
                isLoaded: true,
                quantity: '0',
                price: '0',
                pricePiece: '0',
                priceCoupage: '0',
                pricePercage: '0',
                priceFraisurage: '0',
                isDisFraisurable: isDisFraisurableSt,
                diameter: diameterSt,
                anthraxe: anthraxeSt,
                fraisurableFree: false,
                errorQuantity: false,
                nextFree: false,
              });
            });
          });
        });
      })
    })
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    window.scrollTo(0, 0);
    if (typeof id !== 'undefined') {
      handleProductsSaved()
    } else {
      handleProducts()
    }
  }, [])

  if (values.isLoaded) {
    return (
      <div>
        <MetaTags>
          <title>{data.blocks['seo_outil'].title }</title>
          <meta name="description" content={data.blocks['seo_outil'].text } />
          <meta property="og:title" content={data.blocks['seo_outil'].title } />
        </MetaTags>
        <div>
          <Grid container spacing={0} justify="space-between" alignItems="center" alignContent="center">
            <Grid item xs={12} lg={5} className={classes.header_content}>
              <CrayonIcon className={classes.buttonSvg1} />
              <h1 className={classes.header_class}>{translations["link_projects_new_title"]}</h1>
            </Grid>
            <Grid item xs={12} lg={5}>
              <HeaderCart translations={translations} {...props} />
            </Grid>
          </Grid>
        </div>
        <div className={classes.account_content}>
          <div className={classes.root}>
            <Grid container spacing={5} justify="space-between" alignItems="space-between">
              <Grid item xs={12} lg={12}>
                <Grid container spacing={2} justify="space-between" alignItems="space-between">
                  <Grid item xs={12} lg={5}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} lg={6}>
                        <FormControl fullWidth className={classes.margin}>
                          <InputLabel htmlFor="standard-adornment-amount">{translations["link_projects_name"]}</InputLabel>
                          <Input
                            id="standard-title"
                            value={values.title}
                            onChange={handleChange('title')}
                            variant="filled"
                          />
                        </FormControl>
                      </Grid>
                      <Grid  item xs={12} lg={3}>
                        <CrayonIcon className={classes.buttonSvg1} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6} alignItems="right">
                    {logged ?
                    <Grid container spacing={2} justify="flex-end">
                      <IconButton color="primary" aria-label="upload picture" component="span">
                        <SaveIcon onClick={saveProject} className={classes.buttonSvg1} />
                      </IconButton>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">{translations["project_saved"]}</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {translations["project_saved_help"]}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} color="primary" autoFocus>
                            {translations["close"]}
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <IconButton color="primary" aria-label="upload picture" component="span">
                        <TrashIcon onClick={deleteProject} className={classes.buttonSvg2} />
                      </IconButton>
                    </Grid>
                    :
                    <Grid container spacing={2} justify="flex-end">
                      <IconButton onClick={handleClickOpen} color="primary" aria-label="upload picture" component="span">
                        <SaveIcon className={classes.buttonSvg1} />
                      </IconButton>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">{translations["project_saved_disconnect"]}</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {translations["project_saved_help_disconnect"]}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Link to={'/' + language + '/' + translations["link_account_url"]}>
                            <Button onClick={handleClose} color="primary" autoFocus>
                              {translations["link_login_url"]}
                            </Button>
                          </Link>
                          <Button onClick={handleClose} color="primary" autoFocus>
                            {translations["close"]}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <h3 className={classes.title_class  + ' ' +  classes.mt25}>{translations["link_projects_matiere"]}</h3>
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <Select
                      labelId="demo-simple-select-label"
                      variant="standard"
                      color="secondary"
                      fullWidth
                      id="demo-simple-select"
                      value={values.matiere}
                      onChange={handleChangeMatiere('matiere')}
                      placeholder={translations["link_create_account_siret"]}
                      className={classes.form}
                    >
                    {values.matieres.map((value, index) => (
                      <MenuItem value={value}>{value}</MenuItem>
                    ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <p className={classes.subtitle_class + ' ' + classes.subtitle_class_b}>B</p>
                    <h3 className={classes.title_class}>{translations["link_projects_largeur"]}</h3>
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <Grid className={classes.slider} container spacing={2} alignItems="center">
                      <Grid item className={classes.range}>
                        {Math.min.apply(Math, values.largeurs)} mm
                      </Grid>
                      <Grid item xs>
                        <Slider
                          defaultValue={values.largeur}
                          value={values.largeur}
                          onChange={handleChangeSlider}
                          valueLabelFormat={valuetext}
                          valueLabelDisplay="on"
                          aria-labelledby="discrete-slider-always"
                          track={false}
                          step={null}
                          color="primary"
                          scale={(x) => x * 1}
                          min={Math.min.apply(Math, values.largeurs)}
                          max={Math.max.apply(Math, values.largeurs)}
                          marks={valueslider(values.largeurs)}
                        />
                      </Grid>
                      <Grid item className={classes.range}>
                        {Math.max.apply(Math, values.largeurs)} mm
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <p className={classes.subtitle_class + ' ' + classes.subtitle_class_c}>c</p>
                    <h3 className={classes.title_class}>{translations["link_projects_epaisseur"]}</h3>
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <Grid className={classes.selectEpaisseur} container spacing={0}>
                        {values.epaisseurs.map((value, index) => (
                          <Grid item>
                          <label className={classes.epaisseur}>
                            <input
                              type="radio"
                              name="epaisseur"
                              value={value}
                              className={classes.inputEpaisseur}
                              checked={values.epaisseur == value}
                              onChange={handleChangeEpaisseur('epaisseur')}
                            />
                            <div className={classes.epaisseur}>
                              {value}
                            </div>
                          </label>
                        </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <p className={classes.subtitle_class + ' ' + classes.subtitle_class_a}>a</p>
                    <h3 className={classes.title_class}>{translations["link_projects_longueur"]}</h3>
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <Select
                      labelId="demo-simple-select-label"
                      variant="standard"
                      color="secondary"
                      fullWidth
                      id="longueur"
                      value={values.longueur}
                      onChange={handleChange('longueur')}
                      placeholder={translations["link_create_account_siret"]}
                      className={classes.form}
                    >
                    {values.longueurs.map((value, index) => (
                      <MenuItem value={value}>{value}</MenuItem>
                    ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid container className={classes.options} spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <h2 className={classes.header2_class}>{translations["link_projects_new_options"]}</h2>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container alignItems="flex-start">
                  <Grid item xs={12} lg={6}>
                    <Grid container className={classes.mt25} spacing={2} alignItems="flex-start">
                      <Grid item xs={6} lg={12} xl={6}>
                        <h3 className={classes.title_class}>{translations["link_projects_poicon"]}</h3>
                        {values.checkedPercable ?
                        <h4 className={classes.free_class}>{translations["link_projects_perce"]}</h4>
                        :
                        <h4 className={classes.free_class}>{translations["link_projects_perce_off"]}</h4>
                        }
                      </Grid>
                      <Grid item xs={6} lg={12} xl={6}>
                        <Switch
                          checked={values.checkedPercable}
                          onChange={handleSwitchPercable}
                          name="checkedPercable"
                          disabled={values.isDisPercable}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container className={classes.mt25} spacing={2} alignItems="flex-start">
                      <Grid item xs={6} lg={12} xl={6}>
                        <h3 className={classes.title_class}>{translations["link_projects_fraissurage"]}</h3>
                        {values.fraisurableFree ?
                        <h4 className={classes.free_class}>{translations["link_projects_fraissurage_free"]}</h4>
                        :
                        <h4 className={classes.free_class}>&nbsp;</h4>
                        }
                      </Grid>
                      <Grid item xs={6} lg={12} xl={6}>
                        <Switch
                          checked={values.checkedFraisurable}
                          onChange={handleSwitch}
                          name="checkedFraisurable"
                          disabled={values.isDisFraisurable}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={12} lg={6} spacing={2} alignItems="space-between">
                <Grid container spacing={0}>
                  {!values.checkedPercable ?
                  <img width="200" height="139" className={styles.imgfluid} src={charniere} />
                  :
                  <img width="200" height="139" className={styles.imgfluid} src={charnierepercee} />
                  }
                </Grid>
                <Grid container spacing={2} className={classes.total_class}>
                  <Grid container spacing={0} className={classes.totalprice_class} direction="row" justify="center" alignItems="center">
                    <Grid item xs>
                      <span className={classes.title_class}>{translations["link_projects_quantity"]}</span>
                    </Grid>
                    <Grid item xs>
                      <ButtonGroup size="large" color="primary" aria-label="outlined primary button group">
                        <Button onClick={redQuantity}>-</Button>
                        <Input className={classes.input_quantity} id="quantity" value={values.quantity} disabled />
                        <Button onClick={addQuantity}>+</Button>
                      </ButtonGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      {!values.errorQuantity ?
                      <h3 className={classes.price_class}><NumberFormat value={values.price} displayType={'text'} thousandSeparator={false} decimalScale={2} suffix={'€'} /></h3>
                      :
                      <p className={classes.error_stock}>{translations["error_stock"]}</p>
                      }
                      {values.nextFree ?
                      <p className={classes.error_stock}>{translations["next_free"]}</p>
                      :''
                      }
                      {/*
                      <p className={classes.price_class}>REF: <NumberFormat value={values.pricePiece} displayType={'text'} thousandSeparator={false} decimalScale={2} suffix={'€'} /></p>
                      <p className={classes.}>C: {values.priceCoupage}€ P: {values.pricePercage}€ F: {values.priceFraisurage}€</p>
                      */}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction="row" justify="flex-end" alignItems="center">
                    {!logged ?
                    <Link className={classes.link} to={'/' + locale + '/' + translations["link_login_url"]}>
                      <Button fullWidth variant="contained" color="inherit" className={classes.button1}>{translations["error_login"]}</Button>
                    </Link>
                    :''
                    }
                    {values.quantity != 0 && logged ?
                    <Button onClick={addCart} className={classes.add_cart} variant="outlined" size="large" color="primary">
                    {translations["link_projects_add_to_cart"]}
                    </Button>
                    :''
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={classes.table_content}>
          <TableContainer component={Paper}>
            {!values.checkedPercable ?
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translations["link_projects_matiere"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_a}>A</div> {translations["link_projects_longueur"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_b}>B</div> {translations["link_projects_largeur"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_c}>C</div> {translations["link_projects_epaisseur"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_d}>D</div> {translations["link_projects_broche"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_d}>E</div> {translations["link_projects_charnon"]}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{values.matiere}</TableCell>
                  <TableCell align="center">{values.longueur}</TableCell>
                  <TableCell align="center">{values.largeur}</TableCell>
                  <TableCell align="center">{values.epaisseur}</TableCell>
                  <TableCell align="center">{values.broche}</TableCell>
                  <TableCell align="center">{values.charnon}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            :
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translations["link_projects_matiere"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_a}>A</div> {translations["link_projects_longueur"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_b}>B</div> {translations["link_projects_largeur"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_c}>C</div> {translations["link_projects_epaisseur"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_d}>D</div> {translations["link_projects_broche"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_d}>E</div> {translations["link_projects_charnon"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_d}>F</div> {translations["link_projects_entraxe"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_d}>G</div> {translations["link_projects_pas"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_d}>S</div> {translations["link_projects_diametre"]}</TableCell>
                  <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_d}>V</div> {translations["link_projects_bord"]}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{values.matiere}</TableCell>
                  <TableCell align="center">{values.longueur}</TableCell>
                  <TableCell align="center">{values.largeur}</TableCell>
                  <TableCell align="center">{values.epaisseur}</TableCell>
                  <TableCell align="center">{values.broche}</TableCell>
                  <TableCell align="center">{values.charnon}</TableCell>
                  <TableCell align="center">{values.anthraxe}</TableCell>
                  <TableCell align="center">{values.charnon * 2}</TableCell>
                  <TableCell align="center">{values.diameter}</TableCell>
                  <TableCell align="center">{values.charnon}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            }
          </TableContainer>
        </div>
        {/*
        <div className={classes.table_content}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Matière (att5)</TableCell>
                  <TableCell align="right">A (att2)</TableCell>
                  <TableCell align="right">B (att103)</TableCell>
                  <TableCell align="right">C (att104)</TableCell>
                  <TableCell align="right">D (att121)</TableCell>
                  <TableCell align="right">E (att115)</TableCell>
                  <TableCell align="right">F (att122)</TableCell>
                  <TableCell align="right">G (att123)</TableCell>
                  <TableCell align="right">S (att110)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.products_filter.map(({ id, att2, att5, att103, att104, att110, att115, att121, att122, att123 }, index) => (
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      {id}
                    </TableCell>
                    <TableCell align="right">{att5}</TableCell>
                    <TableCell align="right">{att2}</TableCell>
                    <TableCell align="right">{att103}</TableCell>
                    <TableCell align="right">{att104}</TableCell>
                    <TableCell align="right">{att121}</TableCell>
                    <TableCell align="right">{att115}</TableCell>
                    <TableCell align="right">{att122}</TableCell>
                    <TableCell align="right">{att123}</TableCell>
                    <TableCell align="right">{att110}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        */}
      </div>
    )
  } else {
    return null;
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
    logged: state.accountReducer.logged,
    details: state.accountReducer.details,
    cart: state.accountReducer.cart,
    base: state.paramsReducer.base,
    products: state.productsReducer.list,
  }
}

export default connect(mapStateToProps)(Project);
